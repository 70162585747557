<template>
  <div
    class="app-card-clickable user-card"
    tabindex="0"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <div class="user-card-header">
      <AppUserAvatar :src="display.imageUrl" />
      <div style="display: grid; align-content: center">
        <p style="font-family: LuciferSans">{{ display.userName }}</p>
        <p>
          <span style="color: var(--color-primary)">
            {{ display.travelerType }}
          </span>
          <span v-if="display.country" style="color: #aaa">
            &nbsp;&nbsp;·&nbsp;&nbsp;{{ display.country }}
          </span>
        </p>
      </div>
    </div>
    <AppDashedLine />
    <div class="user-card-content">
      <div v-if="display.hasLocation">
        <p>HOME AIRPORT</p>
        <p>{{ display.location }}</p>
      </div>
      <div>
        <p>CONTACT</p>
        <p>{{ display.contact }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppDashedLine from '../../AppDashedLine.vue'
import AppUserAvatar from '../../AppUserAvatar.vue'

export default {
  name: 'UserCard',
  components: { AppDashedLine, AppUserAvatar },
  data() {
    return { imageLoadError: false }
  },
  computed: {
    ...mapState('UserModule', ['user']),
    ...mapGetters('UserModule', ['userLocation']),
    ...mapGetters('TitleModule', ['getFullName']),
    display() {
      const { travelerType, phone, phoneCountry, email } = this.user
      const { city, country } = this.userLocation?.display ?? {}
      const displayPhone = phoneCountry?.dialCode
        ? `${phoneCountry?.dialCode} ${phone}`
        : phone

      return {
        country,
        hasLocation: !!this.userLocation,
        location: [city, country].filter(el => !!el).join(', '),
        contact: `${displayPhone} · ${email}`,
        userName: this.getFullName(this.user),
        travelerType,
        imageUrl: this.user.imageUrl,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.user-card {
  display: grid;
  gap: 1rem;
  font-family: DDin;
  font-size: 15px;
}
.user-card-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1.5rem;
}
.user-card-image-wrapper {
  box-shadow: var(--thumbnail-box-shadow);
  height: 60px;
  width: 60px;
  border-radius: 100%;
  overflow: hidden;
  & > * {
    height: 100%;
    width: 100%;
  }
}
.user-card-content {
  display: grid;
  gap: 0.5rem;
  & > div > p:last-child {
    color: #666;
    font-size: 13px;
  }
}
</style>
