<template>
  <div
    class="user-avatar"
    :style="{
      width: size + 'px',
      height: size + 'px',
    }"
  >
    <img
      v-if="src && !imageLoadError"
      :width="size"
      :height="size"
      :src="src"
      alt="profile picture"
      @error="imageLoadError = true"
    />
    <div
      v-else
      style="display: flex; align-items: center; justify-content: center"
    >
      <IconPlaceholderUser
        style="color: var(--color-icon); width: 50%; height: 50%"
      />
    </div>
  </div>
</template>

<script>
import IconPlaceholderUser from './icons/placeholders/IconPlaceholderUser.vue'

export default {
  name: 'AppUserAvatar',
  components: { IconPlaceholderUser },
  props: { src: String, size: { type: Number, default: 60 } },
  data() {
    return {
      imageLoadError: false,
    }
  },
}
</script>

<style scoped lang="scss">
.user-avatar {
  box-shadow: var(--thumbnail-box-shadow);
  border-radius: 100%;
  background-color: white;
  overflow: hidden;
  & > * {
    height: 100%;
    width: 100%;
  }
}
</style>
