<template>
  <div class="app-label">
    <p>{{ label }}</p>
    <p v-if="labelRight" class="app-label-right" style="margin-left: auto">
      {{ labelRight }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'AppLabel',
  props: { label: String, labelRight: String },
}
</script>

<style scoped lang="scss">
.app-label {
  font-family: LuciferSans;
  letter-spacing: 0.06em;
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
}
.app-label-right {
  margin-left: auto;
  padding: 0.25rem 0.5rem;
  border-radius: 9px;
  background: var(--color-secondary);
  font-family: DDin;
}
</style>
