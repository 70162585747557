<template>
  <div class="app-dashed-line" :data-direction="direction"></div>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'horizontal',
    },
  },
  name: 'AppDottedLine',
}
</script>

<style scoped lang="scss">
.app-dashed-line[data-direction='horizontal'] {
  border-bottom: 1px dashed #bbb;
  width: 100%;
}
.app-dashed-line[data-direction='vertical'] {
  border-right: 1px dashed #bbb;
  margin-left: -1px;
  width: 1px;
  height: 100%;
}
</style>
