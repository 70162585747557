<template>
  <div class="container-xs">
    <AppBackHeader to="/search/summary">TRAVELER INFORMATION</AppBackHeader>
    <div class="trip-travelers">
      <div>
        <AppLabel label="ME" />
        <router-link v-if="userValid" to="/search/travelers/user-details">
          <UserCard />
        </router-link>
        <router-link v-else to="/search/travelers/user-details">
          <AppButtonCard label="Complete your details" text="Adult" />
        </router-link>
      </div>
      <div v-if="!hasTravelers.isUserOnly">
        <AppLabel label="PASSENGERS" />
        <router-link
          v-for="traveler in activeTravelers.adults"
          :key="traveler.id"
          :to="`/search/travelers/edit/${traveler.id}`"
          class="app-card-clickable"
          >{{ getFullName(traveler) }}</router-link
        >
        <router-link
          v-for="traveler in activeTravelers.children"
          :key="traveler.id"
          :to="`/search/travelers/edit/${traveler.id}`"
          class="app-card-clickable"
          >{{ getFullName(traveler) }}</router-link
        >
        <router-link
          v-for="traveler in activeTravelers.infants"
          :key="traveler.id"
          :to="`/search/travelers/edit/${traveler.id}`"
          class="app-card-clickable"
          >{{ getFullName(traveler) }}</router-link
        >
        <router-link
          v-if="!hasTravelers.adults"
          to="/search/travelers/select?adult"
        >
          <AppButtonCard label="Add" text="Adult" />
        </router-link>
        <router-link
          v-if="!hasTravelers.children"
          to="/search/travelers/select?child"
        >
          <AppButtonCard label="Add" text="Child" />
        </router-link>
        <router-link
          v-if="!hasTravelers.infants"
          to="/search/travelers/select?infant"
        >
          <AppButtonCard label="Add" text="Infant" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AppBackHeader from '@/components-rf/AppBackHeader.vue'
import { mapGetters } from 'vuex'
import AppButtonCard from '../../../components-rf/AppButtonCard.vue'
import AppLabel from '../../../components-rf/AppLabel.vue'
import UserCard from '../../../components-rf/search/passengers/UserCard.vue'

export default {
  name: 'TripTravelers',
  components: {
    AppBackHeader,
    AppButtonCard,
    AppLabel,
    UserCard,
  },
  computed: {
    ...mapGetters('TravelerModule', ['getTravelers']),
    ...mapGetters('TitleModule', ['getFullName']),
    ...mapGetters('SearchState', [
      'activeTravelers',
      'hasTravelers',
      'userValid',
    ]),
  },
}
</script>

<style scoped lang="scss">
.trip-travelers {
  display: grid;
  margin-top: 3rem;
  gap: 1.5rem;
  & > div {
    display: grid;
    gap: 1rem;
  }
}
</style>
