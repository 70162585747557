<template>
  <div
    class="app-button-card app-card-clickable"
    tabindex="0"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <p v-if="text">{{ text }}</p>
    <slot></slot>

    <p class="app-button-card-label">{{ label }}</p>
    <IconArrowRight class="app-button-card-icon" />
  </div>
</template>

<script>
import IconArrowRight from './icons/arrows/IconArrowRight.vue'

export default {
  name: 'AppButtonCard',
  props: { label: String, text: String },
  components: { IconArrowRight },
}
</script>

<style scoped lang="scss">
.app-button-card {
  padding: 1.5rem 1rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  font-family: DDin;
  align-items: center;
}
.app-button-card-label {
  color: #666;
  margin-left: auto;
}
.app-button-card-icon {
  color: black;
}
</style>
